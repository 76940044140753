import { css } from '@emotion/core'

import { theme } from '../styles/theme'
import { config } from './config'
import { media } from './utils'

import GeomanistThin from '../assets/fonts/geomanist-thin-webfont.woff'
import GeomanistThin2 from '../assets/fonts/geomanist-thin-webfont.woff2'

import GeomanistExtralight from '../assets/fonts/geomanist-extralight-webfont.woff'
import GeomanistExtralight2 from '../assets/fonts/geomanist-extralight-webfont.woff2'

import GeomanistLight from '../assets/fonts/geomanist-light-webfont.woff'
import GeomanistLight2 from '../assets/fonts/geomanist-light-webfont.woff2'

import GeomanistRegular from '../assets/fonts/geomanist-regular-webfont.woff'
import GeomanistRegular2 from '../assets/fonts/geomanist-regular-webfont.woff2'

import GeomanistBook from '../assets/fonts/geomanist-book-webfont.woff'
import GeomanistBook2 from '../assets/fonts/geomanist-book-webfont.woff2'

import GeomanistMedium from '../assets/fonts/geomanist-medium-webfont.woff'
import GeomanistMedium2 from '../assets/fonts/geomanist-medium-webfont.woff2'

import GeomanistBold from '../assets/fonts/geomanist-bold-webfont.woff'
import GeomanistBold2 from '../assets/fonts/geomanist-bold-webfont.woff2'

import GeomanistBlack from '../assets/fonts/geomanist-black-webfont.woff'
import GeomanistBlack2 from '../assets/fonts/geomanist-black-webfont.woff2'

import GeomanistUltra from '../assets/fonts/geomanist-ultra-webfont.woff'
import GeomanistUltra2 from '../assets/fonts/geomanist-ultra-webfont.woff2'



export const globalStyle = css`

  /**
  |--------------------------------------------------
  | Fonts
  |--------------------------------------------------
  */

  @font-face {
    font-family: 'Geomanist';
    src: url(${GeomanistThin2}) format('woff2'),
         url(${GeomanistThin}) format('woff');
    font-weight: 100;
    font-style: normal;
  }
  @font-face {
    font-family: 'Geomanist';
    src: url(${GeomanistExtralight2}) format('woff2'),
         url(${GeomanistExtralight}) format('woff');
    font-weight: 200;
    font-style: normal;
  }
  @font-face {
    font-family: 'Geomanist';
    src: url(${GeomanistLight2}) format('woff2'),
         url(${GeomanistLight}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Geomanist';
    src: url(${GeomanistRegular2}) format('woff2'),
         url(${GeomanistRegular}) format('woff');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Geomanist';
    src: url(${GeomanistBook2}) format('woff2'),
         url(${GeomanistBook}) format('woff');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Geomanist';
    src: url(${GeomanistMedium2}) format('woff2'),
         url(${GeomanistMedium}) format('woff');
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: 'Geomanist';
    src: url(${GeomanistBold2}) format('woff2'),
         url(${GeomanistBold}) format('woff');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Geomanist';
    src: url(${GeomanistBlack2}) format('woff2'),
         url(${GeomanistBlack}) format('woff');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Geomanist';
    src: url(${GeomanistUltra2}) format('woff2'),
         url(${GeomanistUltra}) format('woff');
    font-weight: 900;
    font-style: normal;
  }

  /**
  |--------------------------------------------------
  | Global
  |--------------------------------------------------
  */

  /* http://meyerweb.com/eric/tools/css/reset/
    v2.0 | 20110126
    License: none (public domain)
  */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  ol, ul, li {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote::before, blockquote::after,
  q::before, q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  html {
    /* overflow: hidden; */
  }

  body {
    height: 100%;
    // overflow: hidden;
    background: #FFF;
    font-family: ${theme.fonts.sans};
    color: ${theme.colors.body};
    font-style: normal;
    font-size: ${config.baseFontSize}px;
    font-weight: 400;
    line-height: 1.4;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  *:focus {
    outline: none;
  }

  hr {
    width: 100%;
    border: 0;
    height: 0;
    margin: 2em 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  label {
    font-size: 0.8em;
    color: ${theme.colors.textLight};
  }

  a {
    cursor: pointer;
  }

  a:hover {
    opacity: 0.8;
  }

  /**
  |--------------------------------------------------
  | Containers
  |--------------------------------------------------
  */

  .container {
    max-width: ${theme.measurements.containerWidth}px;
    margin: 0 auto;
    padding: 0 ${theme.measurements.paddingDefault};

    ${media.tablet`
      padding: 0 ${theme.measurements.paddingDefaultMobile};
    `}
  }

  /**
  |--------------------------------------------------
  | Typography
  |--------------------------------------------------
  */

  p {
    margin-bottom: 0.8em;
    font-size: 1em;
  }

  .small {
    font-size: 0.8em;
  }

  .spaced {
    line-height: 1.8 !important;
  }

  .bold {
    font-weight: bold;
  }

  .light {
    color: ${theme.colors.textLight};
  }

  h1, h2, h3, h4, h5 {
    font-family: ${theme.fonts.serif};
    color: ${theme.colors.headings};
    // margin: 0 0 0.6em 0;
  }

  .error {
    color: ${theme.colors.error};
  }

  .m-0 {
    margin: 0;
  }

  .mb-1 {
    margin-bottom: 0.2em !important;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  /**
  |--------------------------------------------------
  | Dimensions
  |--------------------------------------------------
  */

  .w-auto {
    width: auto;
  }

  .w-full {
    width: 100%;
  }

  .flex-center {
    display: flex;
    align-items: center;
  }

  /**
  |--------------------------------------------------
  | Other
  |--------------------------------------------------
  */

  .app-version {
    opacity: 0.6;
  }

  .text-center {
    text-align: center;
  }

  /**
  |--------------------------------------------------
  | Grid section layout
  |--------------------------------------------------
  */

  .c-1-4 {
    grid-column: 1 / 4;
  }

  .c-1-3 {
    grid-column: 1 / 3;
  }

  .c-1-5 {
    grid-column: 1 / 5;
  }

  .c-1-6 {
    grid-column: 1 / 6;
  }

  .c-1-8 {
    grid-column: 1 / 8;
  }

  .c-1-9 {
    grid-column: 1 / 9;
  }

  .c-2-8 {
    grid-column: 2 / 8;
  }

  .c-3-5 {
    grid-column: 3 / 5;
  }

  .a-s {
    align-self: start;
  }

  .a-c {
    align-self: center;
  }

  .a-e {
    align-self: end;
  }
`
