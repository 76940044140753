import React from 'react'
import { Global } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'

import { globalStyle } from 'styles/global'
import { theme } from 'styles/theme'

export const App = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyle} />
      {children}
    </ThemeProvider>
  )
}
