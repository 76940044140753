// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-legals-template-js": () => import("/opt/build/repo/src/templates/legalsTemplate.js" /* webpackChunkName: "component---src-templates-legals-template-js" */),
  "component---src-pages-contact-tsx": () => import("/opt/build/repo/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-typography-tsx": () => import("/opt/build/repo/src/pages/typography.tsx" /* webpackChunkName: "component---src-pages-typography-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

