interface IConfig {
  activeScale: string
  baseFontSize: number
  mobileBreakpoint: string
}

export const config: IConfig = {
  activeScale: 'majorThird',
  baseFontSize: 16,
  mobileBreakpoint: 'tablet'
}