export const palette = {
  primary: '#05204A', // oxford blue
  secondary: '#23252A', // dark gunmetal
  tertiary: '#FFF0FF', // lavender blush
  //
  red: '#BA4A47', // deep chestnut
  // https://github.com/hectahertz/react-native-typography
  white: "#FFFFFF",
  grey: "#EFEFF4", // iOSColors.customGray
  grey2: "#E5E5EA", // iOSColors.lightGray
  grey3: "#D1D1D6", // iOSColors.lightGray2
  grey4: "#C7C7CC", // iOSColors.midGray
  grey5: "#8E8E93", // iOSColors.gray
  black: `#38332f`,
  pantoneBlack: '#2D2926'
}

export const theme = {
  colors: {
    hoverBackground: palette.grey3,
    navigationBar: palette.primary,
    defaultBorder: palette.grey,
    darkBorder: palette.grey2,
    // Text
    textLink: palette.secondary,
    textLinkHover: palette.tertiary,
    headings: palette.black,
    body: palette.grey5,
    error: palette.red,
    textLight: palette.grey5,
    // Input
    inputFocus: palette.secondary,
    inputPlaceholder: palette.grey2,
    inputBorder: palette.grey3,
    // Backgrounds
    pageBackground: palette.white,
    contentBackground: palette.white,
    footerBackground: palette.grey,
  },
  scales: {
    // https://www.modularscale.com/?1&em&1.25z
    perfectFifth: [0.667, 1, 1.5, 2.25, 3.375, 5.063],
    majorThird: [0.8, 1, 1.25, 1.563, 1.953, 2.441, 3.052, 3.815, 4.768, 5.96]
  },
  fonts: {
    sans: 'Geomanist, Arial, sans-serif',
    serif: 'Geomanist, Times New Roman, serif'
  },
  measurements: {
    navigationBarHeight: 45,
    navigationBarHeightMobile: 120,
    containerWidth: 1440,
    cardPadding: '1.5em',
    defaultTableMargin: '1.953em 0',
    paddingDefault: '6vw',
    paddingDefaultMobile: '6vw',
    dropdownMenuItemPadding: '0.8em 0.667em',
    sectionPaddingVertical: '6vw',
    // sectionPaddingVertical: '3.815em',
    sectionPaddingVerticalMobile: '5.96em',
    // sectionPaddingVerticalMobile: '1.563em',
    footerHeight: 300
  },
  layers: {
    nav: 2
  },
  buttonStyle: {
    // height: '45px',
    // fontSize: '1em'
    padding: '1.5em 2.25em',
    background: palette.secondary,
    border: 0,
    cursor: 'pointer',
    borderRadius: '2px'
  }
}
