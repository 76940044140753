
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react'
import { App } from './src/App'
import * as mixpanel from 'mixpanel-browser'
// Layouts


/**
 * Setup Providers, Contexts etc
 */
export const wrapRootElement = ({ element }) => {
  return (
    <App id="app">
      {element}
    </App>
  )
}

// A stub function is needed because gatsby won't load this file otherwise
// (https://github.com/gatsbyjs/gatsby/issues/6759)
export const onClientEntry = () => {
  mixpanel.init('5633f876f137ea217b2ad75de785e8ee', {
    debug: true,
  })
}

export const onRouteUpdate = () => {
  let page = window.location.pathname + window.location.search
  // Mixpanel
  mixpanel.track('Page view', {
    page: page,
    referrer: document.referrer,
    // appEnvironment: ENVIRONMENT,
    // appBranch: BRANCH,
    // appVersion: APP_VERSION
  })
}

/**
 * Ensure that all pages have a layout
 */

export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return element
}


export const onRouteUpdateDelayed = () => {
  console.warn("delayed route update detected (> 1s)")
}
