import { css } from '@emotion/core'
import { config } from './config'

/**
|--------------------------------------------------
| Media Queries
|--------------------------------------------------
*/

export const screenSizes = {
  xxxlarge: 2560,
  xxlarge: 1920,
  xlarge: 1440,
  large: 1170,
  desktop: 992,
  tablet: 768,
  phone: 576,
  small: 320
}

// iterate through the screenSizes and create a media template
export const media = Object.keys(screenSizes).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = screenSizes[label] / 16
  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})

/**
|--------------------------------------------------
| Responsive Text within breakpoints:
| font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width])));
|--------------------------------------------------
*/

export const fluidType = (minFontSize = 1, maxFontSize = 1.2, lowerRange = screenSizes.small / config.baseFontSize, upperRange = screenSizes.large / config.baseFontSize) => {
  return `
    font-size: calc(${minFontSize}em + ${maxFontSize / (maxFontSize * 0 + 1) -
    minFontSize / (minFontSize * 0 + 1)} * ((100vw - ${lowerRange}em) / ${upperRange / (upperRange * 0 + 1) -
    lowerRange / (lowerRange * 0 + 1)}));
  `
}

/**
|--------------------------------------------------
| Responsive attribute using px
|--------------------------------------------------
*/

export const fluidPx = (attribute, minPx, maxPx, lowerRange = screenSizes.small, upperRange = screenSizes.large) => {
  return `
    ${attribute}: calc(${minPx}px + ${maxPx / (maxPx * 0 + 1) - minPx / (minPx * 0 + 1)} * ((100vw - ${lowerRange}px) / ${upperRange /
    (upperRange * 0 + 1) -
    lowerRange / (lowerRange * 0 + 1)}));
  `
}
/**
|--------------------------------------------------
| Responsive attribute using em
|--------------------------------------------------
*/

export const fluidEm = (attribute, minEm, maxEm, lowerRange = screenSizes.small, upperRange = screenSizes.large) => {
  return `
    ${attribute}: calc(${minEm}px + ${maxEm / (maxEm * 0 + 1) - minEm / (minEm * 0 + 1)} * ((100vw - ${lowerRange}px) / ${upperRange /
    (upperRange * 0 + 1) -
    lowerRange / (lowerRange * 0 + 1)}));
  `
}

/**
|--------------------------------------------------
| Linear interpolation
|--------------------------------------------------
*/

export const lerp = (min, max, n) => {
  return (1 - n) * min + n * max
}
